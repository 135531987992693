import React from "react";
import { AiFillPhone } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import { FaWhatsappSquare } from "react-icons/fa";
import WhatsAppLink from "./WhatsAppLink";




const TopBar = () => {
  return (
    <div className="flex justify-between items-center px-4 py-2">
      <div className="flex items-center">
        <h1 className="text-xl font-bold text-gray-700">RIEGO MERCEDES</h1>
      </div>
      <div className="flex">
        <div className="hidden md:flex items-center px-6">
          <FaLocationDot
            size={20}
            className="mr-2 text-[var(--primary-dark)]"
          />
          <p className="text-sm text-gray-700">Hipólito Yrigoyen 128, D57321FD - Villa Mercedes (SL)</p>
        </div>
        
        <div className="hidden md:flex items-center px-6">
          <AiFillPhone size={20} className="mr-2 text-[var(--primary-dark)]" />
          <p className="text-sm text-gray-700">+5492657-504891</p>
        </div>
        <div className="hidden md:flex items-center px-6">
          <FaWhatsappSquare size={20} className="mr-2 text-[var(--whats-app-icon)]" />
          <p className="text-sm text-gray-700"> <WhatsAppLink phoneNumber="+5492657504891" /></p>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
