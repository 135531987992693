import React from "react";
import img9 from "../assets/images/9.jpeg";
import img12 from "../assets/images/12.jpeg";
import img13 from "../assets/images/13.png";

const Activities = () => {
  return (
    <>
      <div className="max-w-[1140px] m-auto w-full md:flex mt-[-75px]">
        <div className="grid md:grid-cols-3 gap-4">
          <div className="relative p-4">
            <h3 className="absolute z-10 top-[20%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-gray-800 text-2xl font-bold">
              Campo
            </h3>
            <img
              className="w-full h-full relative border-4 boder-white shadow-lg object-cover"
              src={img9}
              alt="/"
            />
          </div>
          <div className="relative p-4">
            <h3 className="absolute z-10 top-[20%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-gray-300 text-2xl font-bold">
              Parques y Jardines
            </h3>
            <img
              className="w-full h-full relative border-4 boder-white shadow-lg object-cover"
              src={img13}
              alt="/"
            />
          </div>
          <div className="relative p-4">
            <h3 className="absolute z-10 top-[20%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-gray-800 text-2xl font-bold">
              Deportivo
            </h3>
            <img
              className="w-full h-full relative border-4 boder-white shadow-lg object-cover"
              src={img12}
              alt="/"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Activities;
