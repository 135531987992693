import React from "react";
import { AiFillPhone } from "react-icons/ai";
import { FaLocationDot } from "react-icons/fa6";
import { FaWhatsappSquare } from "react-icons/fa";
import WhatsAppLink from "./WhatsAppLink";

const Footer = () => {
  return (
    <>
    <div className="max-2-[1140] w-full py-2 m-auto border-t-4">
      <div className="flex items-center m-auto justify-center">
        <h1 className="text-xl font-bold text-gray-7--">RIEGO MERCEDES</h1>
      </div>
    </div>
    <div className="max-2-[1140] w-full py-2 m-auto">
      <div className="flex items-center m-auto justify-center flex">
      <FaLocationDot
            size={20}
            className="mr-2 text-[var(--primary-dark)]"
          />
          <p className="text-sm text-gray-700">Hipólito Yrigoyen 128, D57321FD - Villa Mercedes (SL)</p>
      </div>
    </div>
    <div className="max-2-[1140] py-2 w-full m-auto">
      <div className="flex items-center m-auto justify-center flex">
      <AiFillPhone size={20} className="mr-2 text-[var(--primary-dark)]" />
          <p className="text-sm text-gray-700">+5492657-504891</p>
      </div>
    </div>
    <div className="max-2-[1140] py-2 w-full m-auto">
      <div className="flex items-center m-auto justify-center flex">
      <FaWhatsappSquare size={20} className="mr-2 text-[var(--whats-app-icon)]" />
      <p className="text-sm text-gray-700"> <WhatsAppLink phoneNumber="+5492657504891" /></p>
      </div>
    </div>
    </>
  );
};

export default Footer;
