import React from "react";
import suppliers from "../assets/images/suppliers.png";

const Suppliers = () => {
  return (
    <div className="max-w-[1140px] m-auto w-full p-4 py-16">
      <h2 className="text-center text-gray-700 p-4">Nuestros Proovedores</h2>
      <div className="grid sm:grid-cols-1 justify-center items-center">
        <div>
          <img className="w-sm h-sm object-cover" src={suppliers} alt="/" />
        </div>
      </div>
    </div>
  );
};

export default Suppliers;
