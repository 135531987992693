import React from 'react';

const PhoneNumberLink = ({ phoneNumber }) => {
  const whatsappLink = `https://wa.me/${phoneNumber}`;
  
  return (
    <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
      WhatsApp
    </a>
  );
};

export default PhoneNumberLink;
