import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true); // Deshabilitar el botón cuando se hace clic

    emailjs
      .sendForm('service_7adih4b', 'template_xgl22aj', form.current, 'esCnAPZlT3c2vU2Sm')
      .then((result) => {
        console.log(result.text);
        alert('¡Su mensaje fue enviado correctamente!');
        form.current.reset();
      })
      .catch((error) => {
        console.log(error.text);
      })
      .finally(() => {
        setIsButtonDisabled(false); // Habilitar el botón después de mostrar la alerta
      });
  };

  return (
    <div id='contact' className='max-w-[1140px] m-auto -w-full p-4 py-16'>
      <h2 className='text-center text-gray-700'>Contáctenos</h2>
      <p className='text-center text-gray-700 py-2'>¡Estamos a su disposición!</p>
      <div className='grid md:grid-cols-2'>
        <img
          className="w-full md:h-full object-cover p-2 max-h-[500px] h-[200px]"
          src="https://www.rainbird.com/sites/default/files/inline-images/leakinghead1077969366rf.jpg"
          alt="/"
        />
        <form ref={form} onSubmit={sendEmail}>
          <div className='grid grid-cols-2'>
            <input className='border m-2 p-2' name="nombre" type="text" placeholder='Nombre' required />
            <input className='border m-2 p-2' name="apellido" type="text" placeholder='Apellido' required />
            <input className='border m-2 p-2' name="email" type="email" placeholder='Email' required />
            <input className='border m-2 p-2' name="telefono" type="tel" placeholder='Teléfono' />
            <textarea className="border col-span-2 m-2 p-2" name="mensaje" cols="30" rows="10" required></textarea>
            <button className='col-span-2 m-2' disabled={isButtonDisabled}>Enviar</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
