import React from "react";

const Hero = () => {
  return (
    <div className="w-full h-[90vh]">
      <img className="w-full h-full object-cover" src="https://www.tldealers.com/themes/kurzweilirr/background.jpg" alt=""/>
      <div className="max-w-[1140px] m-auto">
        <div className="absolute top-[20%] w-full md:-[50%] max-w-[600px] h-full flex flex-col text-black p-4">
        <h1 className="font-bold text-4xl text-gray-800">Riego Mercedes</h1>
        <h2 className="text-xl py-4 italic">La solución a su requerimiento en tecnología de riego</h2>
        <p>
        Venta, asesoramiento, diseño e instalación
        según su necesidad específica.
        Pivotes centrales, caudal discontinuo,
        alas regadoras, riego por aspersión y goteo.
        </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
