import Navbar from "./components/Navbar";
import TopBar from "./components/TopBar";
import Hero from "./components/Hero";
import Activities from "./components/Activities";
import Gallery from "./components/Gallery";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Suppliers from "./components/Suppliers";

function App() {
  return (
    <div>
      <TopBar/>
      <Navbar/>
      <Hero/>
      <Activities/>
      <Gallery/>
      <Suppliers/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
