import React from 'react'
import img4 from "../assets/images/4.jpeg";
import img5 from "../assets/images/5.jpeg";
import img6 from "../assets/images/6.jpeg";
import img10 from "../assets/images/10.jpeg";
import img11 from "../assets/images/11.jpeg";
import img2 from "../assets/images/2.jpeg";

const Gallery = () => {
  return (
    <div id='gallery' className='max-w-[1140px] m-auto w-full p-4 py-16'>
        <h2 className='text-center text-gray-700 p-4'>
            Proyectos
        </h2>
        <div className='grid sm:grid-cols-3 gap-4'>
        <div>
                <img className='w-full h-full object-cover' src={img4} alt='/' />
            </div>
            <div>
                <img className='w-full h-full object-cover' src={img2} alt='/' />
            </div>
            <div>
                <img className='w-full h-full object-cover' src={img5} alt='/' />
            </div>
            <div>
                <img className='w-full h-full object-cover' src={img6} alt='/' />
            </div>
            <div>
                <img className='w-full h-full object-cover' src={img11} alt='/' />
            </div>
            <div>
                <img className='w-full h-full object-cover' src={img10} alt='/' />
            </div>
        </div>
    </div>
  )
}

export default Gallery